import React from 'react'
import { images } from 'config/images'

export const CustomSoftwareDevProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Custom software development services{' '}
        <span className="heading-red">trusted by Fortune 1000 companies</span>
      </>
    ),
    bannerContent: (
      <>
        Reimagine your business into a software-first business with our custom
        software design and development approach
      </>
    ),
    BannerAlt: 'Custom software development services ',
    buttonName: 'Let’s get started!',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for{' '}
        <span className="highlight-head">
          Custom Software Development Services?
        </span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We are a custom software development company delivering quality
            customized software solutions for the web and mobile. Our in-house{' '}
            <span className="color-through animated">development teams'</span>{' '}
            design, develop, deploy and maintain software and aim at a
            predefined set of requirements.
            <br /> <br />
            Our software development cycle passes through several steps,
            including requirements gathering, ideation, architecting systems
            that support iterative releases, constructing clean and testable
            code, as well as quality testing process.
            <br />
            <br />
            What differentiates our custom software development services from
            others is our scalable, robust software products built using quality
            engineering, approaches used to modernize apps, and adherence to
            agile and CI/CD principles throughout the product development life
            cycle.
          </>
        ),
      },
      {
        detailsList: [
          'Complete product ownership',
          'Software quality guaranteed',
          'Flexibility to use your custom software',
          'Advanced tech stacks',
          'Transparent communication and reporting',
          'Guaranteed after-sales support',
          'Consistent delivery with the agile approach',
          'Proven track record in building successful MVP',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        {' '}
        <span className="color-through">Trusted by</span> Fortune companies to
        build custom applications
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  customDevProps: {
    serviceHeading: (
      <>
        Custom Software Development{' '}
        <span className="highlight-head">Offerings</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Application Development Services',
        serviceParagraph: (
          <>
            Optimal business performance through custom software{' '}
            <strong>tailored to complement</strong> your goals and needs.
          </>
        ),
        serviceIcon: images.ServiceEnterpriseAaplication,
        serviceAlt: 'Application Development Services',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: (
          <>
            System Integration <br />
            Services
          </>
        ),
        serviceParagraph: (
          <>
            Through our tech partnerships with modern platforms, we provide{' '}
            <strong>software customization</strong> and integration services.
          </>
        ),
        serviceIcon: images.systemIntegration,
        serviceAlt: 'System Integration Services',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Dashboards, ETL, and BI Services',
        serviceParagraph: (
          <>
            Get systems <strong>“talking to each other”</strong> to make
            business more efficient by putting the right information in the
            right hands.
          </>
        ),
        serviceIcon: images.dashboard,
        serviceAlt: 'Dashboards, ETL, and Reporting Services',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'Legacy Modernization & Application Migration',
        serviceParagraph: (
          <>
            We provide <strong>cloud migration</strong> services, technical
            architecture reviews, and complete application refinement to bring
            your IT up to date.
          </>
        ),
        serviceIcon: images.legacy,
        serviceAlt: 'Legacy Modernization',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: (
          <>
            Big Data Application <br />
            Services
          </>
        ),
        serviceParagraph: (
          <>
            <strong>Custom tools</strong> built to provide the deepest insights
            into your business using the results of high-volume big data
            processing.
          </>
        ),
        serviceIcon: images.bigData,
        serviceAlt: 'Big Data',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Real-time Applications Development Services',
        serviceParagraph: (
          <>
            Harness the <strong>power of technologies</strong> like sensor data
            monitoring, stream processing, etc to get real-time data responses.
          </>
        ),
        serviceIcon: images.realTime,
        serviceAlt: 'Real-time',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  serviceofferingsProps: {
    title: (
      <>
        Custom Software <span className="highlight-head">Types We Offer</span>
      </>
    ),
    Serviceofferings: [
      {
        Title: 'Enterprise application software',
        para: (
          <>
            We customize enterprise-grade software to help businesses like you
            thrive in their business processes. This custom-designed software is
            targeted to your specific set of users and serves their unique
            requirements. Our in-house development teams customize, modernize
            and manage applications that accelerate your workflows.
            <br />
            <br />
            As a result, our products are easy-to-operate solutions that
            optimize and automate your business processes and give 100%
            satisfaction to the users. Our enterprise-based custom software
            application development services include numerous apps.
          </>
        ),
        typeList: [
          {
            title: 'Enterprise Process Management (ERPs) tools',
          },
          {
            title: 'Customer Relationship Management Software (CRMs)',
          },
          {
            title: 'Human Resources Management Software(HRMS)',
          },
          {
            title: 'Content Management Systems (CMS)',
          },
          {
            title: 'Asset Management Software',
          },
        ],
      },
      {
        Title: 'Industrial software',
        para: (
          <>
            We build software products with unique functionalities to serve the
            commercial market, such as managing inventories, operational
            management, financial management, etc. These products have advanced
            functionalities different from existing ready-made solutions.
            <br />
            <br />
            Discover how our set of software but not limited to can help you
            lead the competition, automate your business processes and improve
            productivity.
          </>
        ),
        typeList: [
          {
            title: 'Financial software',
          },
          {
            title: 'Law firm KPI tools',
          },
          {
            title: 'Legal department dashboards',
          },
          {
            title: 'Business intelligence systems',
          },
          {
            title: 'Logistics industry analytics tools',
          },
          {
            title: 'Market research software',
          },
        ],
      },
      {
        Title: 'Specialized services software solutions',
        para: (
          <>
            We develop this set of software specifically to serve
            business-specific functions that your business needs. In addition,
            we strive to offer you exclusive solutions that are difficult to
            meet by the market's existing software solutions.
            <br />
            <br />
            Our customized solutions come with additional features aligned with
            your business's core services and are built using cost-effective
            techniques that suit your budget.
          </>
        ),
        typeList: [
          {
            title: 'Accounting software',
          },
          {
            title: 'Payroll management',
          },
          {
            title: 'Asset management software',
          },
          {
            title: 'Database software',
          },
          {
            title: 'Pharmaceutical inventory and billing systems',
          },
        ],
      },
      {
        Title: 'Customer-centric apps',
        para: (
          <>
            We specialize in creating apps that stand out in providing
            personalized and interactive user experiences and represent a strong
            business identity among your target market. Embrace the flexibility
            of B2B and B2C customer applications that offer a wow-factor to your
            users with minimalistic design and quick responding features.
            <br />
            <br />
            In addition, you will get access to post-sales guidance, customer
            support, and streamlined services for technical support.
          </>
        ),
        typeList: [
          {
            title: 'Social media apps',
          },
          {
            title: 'eCommerce apps',
          },
          {
            title: 'Mobile net banking apps',
          },
          {
            title: 'Patient and healthcare apps',
          },
        ],
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'Get started with your custom software development project by adopting hyper-focused processes on tailoring user experiences and designing super-efficient apps. We ensure you a transparent, trusted, and adaptable bond that drives quality and intelligent solutions.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Custom software for your evolving business needs',
        workingBenefitsContent: (
          <>
            Simform provides custom software development services for the unique
            needs and challenges of your business. We build intuitive, secure{' '}
            <strong>custom applications</strong> to enhance user experience.
          </>
        ),
        benefitImgAlt: 'Build custom applications',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            From an in-depth analysis of your business to{' '}
            <strong>developing custom software</strong> and from user training
            to maintaining applications, we advise on what technologies to
            invest in, what technical architecture to choose, as well as consult
            on how to achieve the required level of data protection.
          </>
        ),
        benefitImgAlt: 'Data protection in custom app',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Understanding your business goals',
        workingBenefitsContent: (
          <>
            Let’s discuss your short and <strong>long-term vision</strong>{' '}
            in-depth. When we know your goals and understand existing software,
            we are able to ensure the success of the project.
          </>
        ),
        benefitImgAlt: 'Business goal for a custom app',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Well-architectured solution for scale',
        workingBenefitsContent: (
          <>
            We take a <strong>pragmatic approach</strong> to software
            architecture and design. Choosing important areas worth investing
            upfront, and prioritize others afterward. We also provide data
            protection consultation.
          </>
        ),
        benefitImgAlt: 'Well-architected solution',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Goal driven user experience (UX) design',
        workingBenefitsContent: (
          <>
            Good software design helps <strong>drive user satisfaction</strong>.
            We perform UX research to align overall experience with your goals
            and users’ needs and let you make well-informed decisions.
          </>
        ),
        benefitImgAlt: 'UX design for bespoke app',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Custom Software</span>
        <br />
        Development Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },

  developSoftAgileProps: {
    heading: (
      <>
        How We Develop Custom
        <br /> Software <span className="highlight-head">Using Agile</span>
      </>
    ),
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Sprint planning',
        workFlowParagraph:
          'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Sprint',
        workFlowTitle: 'Tech architecture',
        workFlowParagraph:
          'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Stand ups & weekly demos',
        workFlowParagraph:
          'Stand ups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Code reviews',
        workFlowParagraph:
          'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Transport',
        contentHeading: <>Fleet management software</>,
        contentPara:
          'Fleet Management Software to control the chaos, improve efficiency, productivity and reduce overall transportation cost.',
        contentSingleProps: [
          {
            singleblkImg: images.fleet1,
            singleblkImg2x: images.fleet12x,
          },
          {
            singleblkImg: images.fleet2,
            singleblkImg2x: images.fleet22x,
          },
          {
            singleblkImg: images.fleet3,
            singleblkImg2x: images.fleet32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: <>Wealth management system</>,
        contentPara:
          'Custom Wealth Management multi-module platform for wealth managers dedicated to retail, affluent and private banking client segments.',
        contentSingleProps: [
          {
            singleblkImg: images.wealthManagement1,
            singleblkImg2x: images.wealthManagement12,
          },
          {
            singleblkImg: images.wealthManagement2,
            singleblkImg2x: images.wealthManagement22,
          },
          {
            singleblkImg: images.wealthManagement3,
            singleblkImg2x: images.wealthManagement32,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: <>Media streaming platform</>,
        contentPara:
          'A highly scalable & customized Media Streaming Platform software for on-demand video content distribution and custom CMS.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: <>Enterprise resource planning system</>,
        contentPara:
          "All-in-one tool for managing your organization's scope of operation and set up a digital transformation process with our full-scale tech consulting.",
        contentSingleProps: [
          {
            singleblkImg: images.enterprise1,
            singleblkImg2x: images.enterprise12x,
          },
          {
            singleblkImg: images.enterprise2,
            singleblkImg2x: images.enterprise22x,
          },
          {
            singleblkImg: images.enterprise3,
            singleblkImg2x: images.enterprise32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: <>E-learning platform</>,
        contentPara:
          'An e-learning platform with user-centred design standards for content delivery & assessment process with high security, accessibility and data maintenance.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: <>E-learning platform</>,
        contentPara:
          "A single platform built using a metric-based approach for managing doctor & patient's appointments, inventory, human resources, and payroll. ",
        contentSingleProps: [
          {
            singleblkImg: images.elearning1,
            singleblkImg2x: images.elearning12,
          },
          {
            singleblkImg: images.elearning2,
            singleblkImg2x: images.elearning22,
          },
          {
            singleblkImg: images.elearning3,
            singleblkImg2x: images.elearning32,
          },
        ],
      },
      {
        tabName: 'Oil & Gas',
        contentHeading: <>Oil & gas exploration software</>,
        contentPara:
          'A geo location-based oil and gas resources exploration software for resource and value assessment through precise data forecasting and intuitive analytics.',
        contentSingleProps: [
          {
            singleblkImg: images.oilgas1,
            singleblkImg2x: images.oilgas12x,
          },
          {
            singleblkImg: images.oilgas2,
            singleblkImg2x: images.oilgas22x,
          },
          {
            singleblkImg: images.oilgas3,
            singleblkImg2x: images.oilgas32x,
          },
        ],
      },
      {
        tabName: 'Retail',
        contentHeading: <>E-commerce supply chain management</>,
        contentPara:
          'With Inventory Management Software, efficiently manage your supply chain, product orders, logistics & shipping, forecasting and product returns.',
        contentSingleProps: [
          {
            singleblkImg: images.ecommerce1,
            singleblkImg2x: images.ecommerce12x,
          },
          {
            singleblkImg: images.ecommerce2,
            singleblkImg2x: images.ecommerce22x,
          },
          {
            singleblkImg: images.ecommerce3,
            singleblkImg2x: images.ecommerce32x,
          },
        ],
      },
    ],
  },
  awardsRecognitionProps: {
    heading: 'Awards & Recognition',
    awardBlockProps: [
      // {
      //   awardBlockImg: images.microsoftgold,
      // },
      {
        awardBlockImg: images.awsPartnerLogo,
      },
      {
        awardBlockImg: images.cmmiLogo,
      },
      {
        awardBlockImg: images.clutchGlobalLogo,
      },
      {
        awardBlockImg: images.theSteviesLogo,
      },
      {
        awardBlockImg: images.glassdoorfullLogo,
      },
      {
        awardBlockImg: images.GPTWLogo,
      },
      {
        awardBlockImg: images.atechchrunch,
      },
      {
        awardBlockImg: images.awwwardswebsitelogo,
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle:
          'What is custom software development? What are custom applications?',
        faqPara: (
          <>
            <strong>Custom software development</strong> is the development of
            software for the specific needs of a business process or group of
            users. Such applications serve the goals of a business by providing
            features and workflows generally not found in more traditional and
            widespread off-the-shelf software.
          </>
        ),
      },
      {
        faqTitle: 'How much does custom software development cost?',
        faqPara: (
          <>
            In our experience, many custom software projects fall somewhere
            between the $50,000 and $500,000 mark to design and develop.
            <br /> <br />
            It’s a broad range, and probably not that useful because the
            software can mean anything from a calculator on your phone to a full
            enterprise billing system that supports millions of users. No two
            custom-built applications are the same.
            <br /> <br />
            Biggest Factors for Software Development Cost:
            <br /> <br />
            <ul>
              <li>Size of project and number of features</li>
              <li>Complexity</li>
              <li>Third-party integrations</li>
              <li> Design complexity</li>
              <li>Technology stack</li>
              <li>Developer’s skillset</li>
              <li> Velocity required</li>
              <li> Development Process</li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'How do you choose the right tech architecture?',
        faqPara: (
          <>
            That is not an easy answer, and like many other answers related to
            software development, it depends. We follow the concept of
            Evolutionary Architectures.
            <br /> <br />
            We picture your project as a set of different Modules, each module
            can vary in complexity and requirements. Therefore each module can
            have a proper architecture that best suits it. Despite that, we have
            to think about architecture as living beings, so they will adapt and
            change during the project.
            <br /> <br />
            Choosing a design for the whole system up front usually is not the
            best idea. We would recommend you to picture your system as this set
            of modules and start to choose a design that best addresses each
            module needs, always favoring simplicity.
          </>
        ),
      },
      {
        faqTitle: 'What technologies will be used to develop custom software?',
        faqPara: (
          <>
            We are technology neutral and strongly believe that each technology
            has its own pros and cons. It depends on the skills of the
            developers rather than the technology itself.
            <br /> <br />
            We work on a range of technologies, frameworks, and programming
            languages for both server side and front-end side.
            <br /> <br />
            Backend - Node.js, .Net Core, asp.Net (C#), PHP, Python, Java, Ruby
            On Rails, Go
            <br /> <br />
            Frontend - JavaScript, TypeScript, Angular, React, Vue, iOS,
            Android, Electron, Unity, WPF, React Native, Flutter, Xamarin
            <br /> <br />
            Databases - PostgresQL, SQL Server, MySQL, Oracle, Firebase,
            Firestore, MongoDB, DynamoDB, Couchbase, Cassandra, Hbase, Redis,
            ElasticSearch, RethinkDB, Cosmos DB, Neptune, Hadoop, Hortonworks,
            MAPR, Cloudera, etc.
            <br /> <br />
            CMS - Liferay, Sitecore, Magento, Shopify, Wordpress, Moodle,
            Umbraco, Canvas, Adapt, Docebo, Litmos, Successfactors, Totara, etc.
            <br /> <br />
            Cloud & Integrations - Salesforce, Sharepoint, Amazon AWS, Microsoft
            Azure, Google Cloud, Kubernetes, Docker, Mulesoft, Pivotal, Algolia,
            Apigee, Splunk, Talend, Nexmo, Twilio, Alfresco, Stripe etc.
            <br /> <br />
            Testing - Selenium, Saucelabs, Appium, Mocha, Katalon, SoapUI,
            Gatling, JMeter, Hoverfly
            <br /> <br />
            DevOps - Jenkins, CircleCI, TravisCI, Codeship, Gradle
          </>
        ),
      },
      {
        faqTitle: 'How do you make sure software has great UI and UX?',
        faqPara: (
          <>
            Our developers are creative technologists who also understand
            design. This unique combination of skills ensures that our designs
            will fit within the framework of your systems and give your
            customers a better experience.
            <br /> <br />
            Building software with great UX requires changing the Agile process
            to fit in UX designers needs:
            <br />
            <ul>
              <li>Understand technical constraints first to save time </li>
              <li>Research and test before, during, and after development</li>
              <li>
                Have time to design for both microinteractions and the big
                picture
              </li>
              <li>Adapt quickly to new information.</li>
              <li>Be quick.</li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'What are the examples of customized application software?',
        faqPara: (
          <>
            Examples of <strong>custom software applications</strong> include:
            <br />
            <ul>
              <li>eCommerce web and mobile apps</li>
              <li>Customer-facing web portals</li>
              <li>Video streaming apps</li>
              <li>Banking and finance applications</li>
              <li>Delivery services apps</li>
              <li>Voice recognition software</li>
              <li>Gaming applications</li>
              <li>Real-time tracking software</li>
              <li>Healthcare apps</li>
              <li>Social media platforms</li>
            </ul>
            <br />
            Any software specifically developed for organizations or clients
            tailored for a specific target market is custom software. Its
            features and functionalities vary from requirement and time to time
            based on the end users' and current market demand.
          </>
        ),
      },
      {
        faqTitle: 'What is the essence of custom software?',
        faqPara: (
          <>
            A tailor-made, efficient, and scalable solution with user-friendly
            interactions and software support makes customized software's
            essence.
            <br />
            <br />
            Your custom software is a targeted solution to address your exact
            needs. Unlike off-the-shelf solutions, customized software is not
            limited to the startup phase of your business model. This comes with
            as many new software integrations as you want without having to
            experience any integration issues and hardware costs.
            <br />
            <br />
            These qualities should be the essence of any customized software
            solution:
            <br />
            <ul>
              <li>User-friendly and interactive user experience</li>
              <li>Should be able to migrate with all existing records</li>
              <li>
                Should be automating tasks, especially where requirements
                account for repetitive tasks
              </li>
              <li>Flexible and scalable</li>
              <li>
                Unique from existing solutions that represent your business as a
                brand
              </li>
              <li>
                Light-weight- not loaded with unnecessary features that only
                increase the cost
              </li>
              <li>
                Designed to offer extensive security than mainstream software
              </li>
            </ul>
            <br />
            We ensure that our customized software fulfills business-specific
            needs and reports fewer complications than traditional or existing
            competitor software. We aim to provide as many convenient features
            and easy-to-handle functionalities in our custom apps by optimizing
            them for interface complications. <br />
            <br />
            Also, we design our custom systems considering all business needs,
            including small-sized startups for whom preventing inconsistent data
            and securing end-users information matters the most.
          </>
        ),
      },
      {
        faqTitle: 'Why is custom development best for your business?',
        faqPara: (
          <>
            Here's why custom software development is best for your business:
            <br />
            <ul>
              <li>
                You don't need to spend time and money finding and changing
                software as your business needs change.
              </li>
              <li>
                You can save a lot of money investing in commercial software to
                integrate with existing systems by developing custom software
                that blends well with all your systems.{' '}
              </li>
              <li>
                You can monetize your custom-built software anytime and earn
                profits.
              </li>
              <li>
                With custom software development, you can scale your business
                processes and customer base as much as you want without worrying
                about additional licensing or subscription costs.
              </li>
              <li>
                You receive instant solutions for your technical problems since
                you have a dedicated team/individuals for technical support.{' '}
              </li>
              <li>
                You can personalize customer experience for your end-users as
                and when required by adding customized features, unlike
                traditional ready-made software.
              </li>
              <li>
                <strong>Custom software services</strong> enable you to quickly
                implement innovative ideas in one place without spending months
                adapting different software.
              </li>
            </ul>
          </>
        ),
      },
      {
        faqTitle: 'Benefits of custom software solutions?',
        faqPara: (
          <>
            Whether you leverage <strong>custom software services</strong> for
            in-house employees of your organization or end-user perspective, it
            has numerous benefits under its belt.
            <br />
            <br />
            Firstly, the in-house custom software helps you achieve greater
            productivity levels as an organization. Furthermore, it promotes
            work efficiency among the employees by being more driven and
            supportive, resulting in less time and more work. It may seem
            hesitant to invest in custom software application development at
            first due to its costs, but it becomes a wise investment in the long
            run.
            <br />
            <br />
            Our <strong>custom software developers</strong> build personalized
            applications that stand out in the competitive market of the{' '}
            <strong>custom development software</strong> and remain intuitive
            solutions for end-users.
            <br />
            <br />
            Simform's <strong>
              custom application development services
            </strong>{' '}
            ensure continuous support and maintenance, scalable & robust
            solutions, seamless integration, and quality product delivery in
            less time.
            <br />
            <br />
            Our <strong>custom software company</strong> boasts technical
            proficiency in selecting suitable tech stacks helps us optimize the
            time, effort, and cost. This also promotes synergy between
            cross-functional teams, brings clarity at all levels, and gives
            flawless customer experience to our client base and their end users.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/custom-software-development-costs/',
        featurecardTitle:
          'How to Estimate Custom Software Development Costs for your Projects?',
      },
      {
        blogLink:
          'https://www.simform.com/blog/custom-software-vs-off-the-shelf/',
        featurecardTitle:
          'Custom Software vs Off-the-shelf: Best Approach for Business Growth',
      },
      {
        blogLink:
          'https://www.simform.com/make-gps-vehicle-fleet-tracking-system-hardware/',
        featurecardTitle:
          'How to Make a GPS tracking device for Vehicle and Fleet Tracking',
      },
    ],
  },
}
